<template>
	<div>
		<section class="section is-main-section" v-if="tab === 0">
			<div class="box">
				<b-field label="site adı">
					<b-input v-model="form.name" />
				</b-field>
				<b-field label="site panel kullanıcısı">
					<b-input v-model="form.username" />
				</b-field>
				<b-field label="site callback url">
					<b-input v-model="form.callbackUrl" />
				</b-field>
				<div
					v-for="(type, key) in depositTypes"
					:key="key"
					v-if="form.depositSettings">
					<b-field group-multiline grouped>
						<b-field :label="type + ' Yatırım Komisyonu'" expanded>
							<b-input
								size="is-small"
								v-model="form.depositSettings[type].depositRate"
								required />
						</b-field>
						<b-field :label="type + ' Chat ID'" expanded>
							<b-input
								size="is-small"
								v-model="form.depositSettings[type].chatId" />
						</b-field>
					</b-field>
					<hr />
				</div>
				<b-button @click="init" type="is-warning" class="mr-3"
					>limitleri gir</b-button
				>
				<b-button @click="save" type="is-success">kaydet</b-button>
			</div>
		</section>
		<section class="section is-main-section" v-if="tab === 1">
			<div class="box">
				<ul>
					<li v-for="(item, key) in userData" :key="key">
						{{ item.name }}: {{ item.value }}
					</li>
				</ul>
			</div>
		</section>
	</div>
</template>

<script>
	import { SiteService, UserService } from "@/services"

	export default {
		name: "ApiCreator",
		data() {
			return {
				form: {
					name: null,
					username: null,
					callbackUrl: process.env.VUE_APP_BASE_URL + "callback",
					depositSettings: null,
				},
				userData: {},
				tab: 0,
			}
		},
		methods: {
			init() {
				this.form.depositSettings = {}
				this.depositTypes.map((item) => {
					if (!this.form.depositSettings[item])
						this.form.depositSettings[item] = {
							depositRate: "",
							chatId: "",
						}
				})
			},
			async save() {
				if (!this.form.name || !this.form.username) return
				const form = { ...this.form }
				form.password = this.generateP(6)
				form.site = {
					name: form.name,
					depositSettings: form.depositSettings || {},
					status: 1,
					callbackUrl: form.callbackUrl,
					withdrawRate: 0,
				}
				const site = await SiteService.create(form.site)
				form.apiUser = {
					username: form.username.toLowerCase().trim() + ".api",
					password: form.password,
					email:
						form.username.toLowerCase().trim() +
						this.generateP(5) +
						"@" +
						process.env["VUE_APP_NAME"],
					type: "SITE",
					roleId: 5,
					appKey: this.generateP(64),
					appSecret: this.generateP(64),
					siteId: site.data.uuid,
				}
				await UserService.create(form.apiUser)
				form.panelUser = {
					username: form.username.toLowerCase().trim(),
					email:
						form.username.toLowerCase().trim() +
						this.generateP(5) +
						"@" +
						process.env["VUE_APP_NAME"],
					password: form.password,
					type: "PANEL",
					roleId: 5,
					siteId: site.data.uuid,
				}
				await UserService.create(form.panelUser)
				this.tab = 1
				this.userData = {
					panelUrl: {
						name: "Panel URL",
						value: "https://" + process.env["VUE_APP_NAME"],
					},
					panelUsername: {
						name: "Panel Username",
						value: form.panelUser.username,
					},
					panelPassword: {
						name: "Panel Password",
						value: form.panelUser.password,
					},
					apiKey: { name: "App Key", value: form.apiUser.appKey },
					secretKey: {
						name: "Secret Key",
						value: form.apiUser.appSecret,
					},
					documentationUrl: {
						name: "Documentation URL",
						value:
							"https://" +
							process.env["VUE_APP_NAME"] +
							"/v1/documentation",
					},
				}
			},
		},
	}
</script>
